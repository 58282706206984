import React from 'react'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Masaru Suzuki - Vancouver Photographer</title>
        <meta
          name="description"
          content="Masaru Suzuki is a Vancouver-based photographer and student who captures landscape and portrait photos. His photos have been featured by BuzzFeed, and Adobe."
        />
        <meta
          property="og:title"
          content="Masaru Suzuki - Vancouver Photographer "
        />
        <meta
          property="og:description"
          content="Masaru Suzuki is a Vancouver-based photographer and student who captures landscape and portrait photos. His photos have been featured by BuzzFeed, and Adobe."
        />
      </Helmet>
      <section className="home-hero">
        <video
          src="https://orenarystorage.pages.dev/player.mp4"
          loop
          muted
          autoPlay
          playsInline
          className="home-video"
        ></video>
        <header data-thq="thq-navbar" className="home-navbar">
          <div data-thq="thq-burger-menu" className="home-burger-menu"></div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-container1">
                <div
                  data-thq="thq-close-menu"
                  className="home-menu-close"
                ></div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav1"
              >
                <span className="home-text">About</span>
                <span className="home-text01">Features</span>
                <span className="home-text02">Pricing</span>
                <span className="home-text03">Team</span>
                <span className="home-text04">Blog</span>
              </nav>
              <div className="home-container2">
                <button className="home-login button">Login</button>
                <button className="button">Register</button>
              </div>
            </div>
            <div className="home-icon-group">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon2">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon4">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
        <div id="hero" className="home-hero-content">
          <div className="home-header-container">
            <div className="home-header">
              <h1 className="home-heading animate__fadeInDown animate__animated">
                <span>Preserving life&apos;s moments, </span>
                <span className="home-text06">one at a time.</span>
                <br></br>
              </h1>
            </div>
            <p className="home-caption animate__fadeInDown animate__animated">
              <span>
                Welcome to the website of
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text09">Masaru Suzuki</span>
            </p>
            <div className="home-container3 animate__fadeInDown animate__animated">
              <a href="#about" className="home-link button">
                <span>
                  <span className="home-text11">Learn more</span>
                  <br></br>
                </span>
              </a>
              <a
                href="https://portfolio.masarusuzuki.ca"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1 button"
              >
                Portfolio
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="home-note">
        <h2 className="home-caption1 animate__fadeInDown animate__animated">
          <span className="home-text13">
            Masaru Suzuki is a Vancouver-based photographer and student who
            captures landscape and portrait photos. He has worked with CARYC and
            DAYONE Dance, two organizations that are led by youth. His photos
            have been featured by BuzzFeed, UBC, and the City of Vancouver. He
            is passionate about sharing his vision of the world with others and
            spreading positivity through his art.
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h2>
      </section>
      <section className="home-statistics">
        <div className="home-content">
          <div className="home-stat">
            <h3 className="home-header1">5.65M</h3>
            <span className="home-caption2 animate__animated animate__fadeInUp">
              Views on Unsplash
            </span>
          </div>
          <div className="home-stat1">
            <h3 className="home-header2">26.1K</h3>
            <span className="home-caption3 animate__animated animate__fadeInUp">
              Downloads on Unsplash
            </span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header3">1K</h3>
            <span className="home-caption4 animate__animated animate__fadeInUp">
              Uses by people around the world.
            </span>
          </div>
        </div>
      </section>
      <section className="home-slider">
        <div className="home-header4">
          <h2 className="home-heading1">
            <span className="home-text15">
              Sharing the
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text16">extraordinary</span>
            <span className="home-text17">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text18">
              in the
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text19">ordinary</span>
            <span className="home-text20"> with photography.</span>
            <span className="home-text21">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </h2>
        </div>
        <p className="home-caption5">
          <span>
            There is magic and beauty in every single moment in life. My goal is
            to capture those and share those with the world, through Instagram,
            Unsplash, and other photography platforms in order so others can
            experience those beautiful moments that I see.
          </span>
          <br></br>
        </p>
      </section>
      <section className="home-not-public">
        <div className="home-header-container1">
          <div className="home-header5">
            <h2 className="home-heading2">
              <span>
                My journey
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text25">never ends</span>
              <span>.</span>
            </h2>
            <p className="home-caption6">
              Exploring different ways and techniques of photography and
              videography.
            </p>
          </div>
          <div className="home-button">
            <button className="button">
              <span>Get started today</span>
            </button>
          </div>
        </div>
        <img
          alt="image"
          src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
          className="home-image"
        />
      </section>
      <section className="home-objectives"></section>
      <section className="home-growth">
        <div className="home-content1">
          <div className="home-header6">
            <div className="home-header7">
              <h2 className="home-heading3">
                <span className="home-text28">
                  What
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text29">
                  working with
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text30">me is like.</span>
                <br className="home-text31"></br>
              </h2>
            </div>
            <p className="home-caption7">
              <span className="home-text32">
                &quot;In the past, working alongside Masaru has always been a
                wonderful experience. He is always easy to communicate with and
                is quick to give eloquent response. His devotion to the project
                at hand is absolutely brilliant as he is always willing to put
                his best foot forward. Not only is Masaru a hard worker, but an
                outstanding leader who strongly voices his concerns with others
                and acts as a team player.&quot;
              </span>
              <br className="home-text33"></br>
              <br className="home-text34"></br>
              <span className="home-text35">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </p>
          </div>
          <div className="home-testimonial">
            <div className="home-information">
              <div className="home-author">
                <img
                  alt="image"
                  src="/playground_assets/325469176_2096071503935814_3400787035194957820_n-200h.jpg"
                  className="home-icon6"
                />
                <span className="home-name">
                  <span className="home-text36">Asia Au-Yeung</span>
                  <br></br>
                  <span>DAYONE DANCE</span>
                  <br></br>
                  <a
                    href="https://dayonedance.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    website
                  </a>
                  <span className="home-text40">
                    {' '}
                    |
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://instagram.com/asiaauyeung"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link3"
                  >
                    instagram
                  </a>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container4">
          <div className="home-images">
            <div className="home-square"></div>
            <img
              alt="image"
              src="/playground_assets/dsc02216-2-2-700w.jpg"
              className="home-image1"
            />
          </div>
          <span className="home-text42">
            Photo captured by Masaru Suzuki of DAYONE DANCE&apos;s Richmond
            Night Market Performance.
          </span>
        </div>
      </section>
      <Footer></Footer>
      <div>
        <DangerousHTML
          html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Home
