import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './copyright.css'

const Copyright = (props) => {
  return (
    <div className="copyright-container">
      <Helmet>
        <title>Copyright - Masaru Suzuki - Vancouver Photographer</title>
        <meta
          name="description"
          content="Masaru Suzuki is a Vancouver-based photographer and student who captures landscape and portrait photos. His photos have been featured by BuzzFeed, and Adobe."
        />
        <meta
          property="og:title"
          content="Copyright - Masaru Suzuki - Vancouver Photographer "
        />
        <meta
          property="og:description"
          content="Masaru Suzuki is a Vancouver-based photographer and student who captures landscape and portrait photos. His photos have been featured by BuzzFeed, and Adobe."
        />
      </Helmet>
      <section id="about" className="copyright-note">
        <h2 className="copyright-caption animate__fadeInDown animate__animated">
          <span>
            As a photographer, I value my creative work and my efforts to create
            original images. I believe my work deserves respect and recognition
            from others who wish to use it for their own purposes.
          </span>
          <br></br>
          <br className="copyright-text02"></br>
          <span>
            {' '}
            Copying without permission or attribution is not fair or respectful
            to me as the creator, nor is it beneficial to society as it inhibits
            innovation and diversity in artistic expression.
          </span>
          <br></br>
          <br className="copyright-text05"></br>
          <span>
            Therefore, anyone wishing to use my work is requested to respect my
            rights and ask for my permission first. I am willing to negotiate
            reasonable terms for licensing or selling my work, depending on the
            purpose and circumstances of use. However, if someone violates my
            rights by violating them without permission or attribution, I will
            not hesitate to take legal action against them in accordance with
            the law.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h2>
        <Link to="/" className="copyright-navlink button">
          <span>
            <span className="copyright-text09">Return back to homepage</span>
            <br></br>
          </span>
        </Link>
      </section>
      <Footer rootClassName="footer-root-class-name1"></Footer>
      <section className="copyright-not-public">
        <div className="copyright-header-container">
          <div className="copyright-header">
            <h2 className="copyright-heading">
              <span>
                My journey
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="copyright-text12">never ends</span>
              <span>.</span>
            </h2>
            <p className="copyright-caption1">
              Exploring different ways and techniques of photography and
              videography.
            </p>
          </div>
          <div className="copyright-button">
            <button className="button">
              <span>Get started today</span>
            </button>
          </div>
        </div>
        <img
          alt="image"
          src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
          className="copyright-image"
        />
      </section>
      <div>
        <DangerousHTML
          html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Copyright
