import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-container">
        <div className="footer-container1">
          <div className="footer-logo">
            <span className="footer-text">
              <span className="">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="">
                I would like to acknowledge that I am on the traditional,
                ancestral, and unceded territory of the xʷməθkʷəy̓əm (Musqueam),
                Sḵwx̱wú7mesh (Squamish), and səlilwətaɬ (Tsleil-Waututh) Nations.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <a
                href="https://vancouver.ca/people-programs/land-acknowledgement.aspx"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link"
              >
                Learn more.
              </a>
            </span>
          </div>
        </div>
        <div className="footer-links-container">
          <div className="footer-container2">
            <div className="footer-product-container">
              <span className="footer-text3">{props.text1}</span>
              <span className="footer-text4">
                <a
                  href="https://www.iubenda.com/privacy-policy/72377713"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link1"
                >
                  Privacy Policy
                </a>
                <br className=""></br>
              </span>
              <span className="footer-text6">{props.text2}</span>
              <Link to="/copyright" className="footer-navlink">
                {props.Copyright}
              </Link>
            </div>
            <div className="footer-company-container">
              <span className="footer-text7">{props.text3}</span>
              <a
                href={props.link_text}
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link2"
              >
                {props.text4}
              </a>
              <a
                href={props.link_text1}
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link3"
              >
                {props.text5}
              </a>
              <a
                href={props.link_text2}
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link4"
              >
                {props.text6}
              </a>
              <a
                href={props.link_text3}
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link5"
              >
                {props.text7}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-separator"></div>
      <a href="#hero" data-role="scroll-top" className="footer-link6 button">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="footer-image"
        />
      </a>
      <div className="footer-container3">
        <span className="footer-text8">{props.text}</span>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  image_src: '/playground_assets/top-angle-arrow-outline-icon-200h.png',
  image_alt: 'image',
  text: '© 2022 Masaru Suzuki - All Rights Reserved (where applicable). Video Background provided by Pexels.',
  text1: 'Product',
  text2: 'Contact',
  text3: 'Social Media',
  text4: 'Instagram',
  link_text: 'https://instagram.com/orenarypaladin',
  text5: 'LinkedIn',
  link_text1: 'https://linkedin.com/in/mthomassuzuki',
  text6: 'Unsplash',
  link_text2: 'https://unsplash.com/@orenarypaladin',
  text7: 'Pexels',
  link_text3: 'https://pexels.com/orenarypaladin',
  rootClassName: '',
  Copyright: 'Copyright',
}

Footer.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  link_text: PropTypes.string,
  text5: PropTypes.string,
  link_text1: PropTypes.string,
  text6: PropTypes.string,
  link_text2: PropTypes.string,
  text7: PropTypes.string,
  link_text3: PropTypes.string,
  rootClassName: PropTypes.string,
  Copyright: PropTypes.string,
}

export default Footer
